<template>
  <v-app style="min-width: 300px">
    <!-- navbar -->
    <div style="position: fixed; top: 0px; backgroundColor: white; height: 64px; width: 100vw; min-width: 300px; z-index: 9999; border-bottom: 0px solid gainsboro">
      <div class="d-flex align-center justify-space-between" style="height: 100%; width: 100vw; min-width: 300px">
        <!-- 로고 -->
        <div class="navbar-logo d-flex align-center">
          <img @click="goHome()" src="@/assets/logo.png" style="cursor: pointer; height: 24px">
        </div>
        <!-- menu -->
        <div class="navbar-menu d-none d-md-flex" style="padding-right: 10vh">
          <div class="d-flex justify-space-between">
            <div @click="goHome()" style="cursor: pointer; color: black" class="mr-5">Home</div>
            <!-- <div @click="goSolution()" style="cursor: pointer; color: black" class="mr-5">솔루션</div> -->

            <v-menu open-on-hover bottom offset-y nudge-left="8">
              <template v-slot:activator="{ on, attrs }">
                <div v-on="on" v-bind="attrs" class="d-flex align-center mr-3">
                  서비스 <v-icon>mdi-chevron-down</v-icon>
                </div>
              </template>
              <v-list dense>
                <v-list-item @click="goRecolumn()" class="text-center">
                  <v-list-item-title>리컬럼</v-list-item-title>
                </v-list-item>
                <v-list-item @click="goInventier()" class="text-center">
                  <v-list-item-title>인벤티어</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <v-menu open-on-hover bottom offset-y nudge-left="8">
              <template v-slot:activator="{ on, attrs }">
                <div v-on="on" v-bind="attrs" class="d-flex align-center mr-0">
                  고객지원 <v-icon>mdi-chevron-down</v-icon>
                </div>
              </template>
              <v-list dense>
                <v-list-item @click="goContact()" class="text-center">
                  <v-list-item-title>도입문의</v-list-item-title>
                </v-list-item>
                <v-list-item @click="goAiVoucher()" class="text-center">
                  <v-list-item-title>AI바우처</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>
        <!-- 햄버거 메뉴 버튼(모바일) -->
        <v-app-bar-nav-icon v-if="!navDrawer" class="d-md-none mr-3" @click="navDrawer = true" v-click-outside="closeNavDrawer"></v-app-bar-nav-icon>
        <v-btn v-else class="d-md-none mr-3" dark icon color="#367BF5" @click="navDrawer = false" v-click-outside="closeNavDrawer">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <!-- 햄버거 메뉴(모바일) -->
      <v-expand-transition>
        <div v-show="navDrawer" style="padding: 0px 0px 12px 24px; backgroundColor: white; border-bottom: 1px solid gainsboro">
          <div @click="goHome()" class="mb-4">Home</div>
          <!-- <div @click="goSolution()" class="mb-4">솔루션</div> -->
          <div class="mb-2">서비스</div>
          <div class="mb-4 ml-4" @click="goRecolumn()">리컬럼</div>
          <div class="mb-4 ml-4" @click="goInventier()">인벤티어</div>
          <div class="mb-2">고객지원</div>
          <div class="mb-4 ml-4" @click="goContact()">도입문의</div>
          <div class="mb-0 ml-4" @click="goAiVoucher()">AI바우처</div>
        </div>
      </v-expand-transition>
    </div>

    <v-main style="padding-top: 64px">
      <router-view/>
    </v-main>

    <!-- footer -->
    <div class="d-flex flex-column align-center justify-center" style="backgroundColor: #191f28; color: white">
      <!-- 웹 -->
      <div class="d-none d-sm-flex flex-column align-center justify-center py-12 px-6 text-center" style="width: 100%">
        <div class="mb-6" style="font-weight: 600">COGCOM Inc.</div>
        <div class="mb-4">(주)코그콤 | 사업자등록번호 : 378-86-01944 | 대표 : 정치훈</div>
        <div class="mb-4">서울특별시 서초구 매헌로 16, 1207호 (AI양재허브) | 02-2088-2096 | contact@cogcom.kr</div>
      </div>
      <!-- 모바일 -->
      <div class="d-sm-none d-flex flex-column align-center justify-center py-6 px-6 text-center" style="width: 100%">
        <div class="mb-4" style="font-weight: 600">Cogcom Inc.</div>
        <div class="mb-1">(주)코그콤 | 대표 : 정치훈</div>
        <div class="mb-3">사업자등록번호 : 378-86-01944</div>
        <div class="mb-1">서울특별시 서초구 매헌로 16, 1207호 (AI양재허브)</div>
        <div class="mb-0">02-2088-2096 | contact@cogcom.kr</div>
      </div>
    </div>

    <!-- fab -->
    <div class="fab-area d-flex flex-column align-center">
      <v-btn fab x-small color="#2196F3" dark outlined class="mb-3" style="background-color: white" @click="goTop()">
        <v-icon>mdi-chevron-up</v-icon>
      </v-btn>
      <v-btn small color="#2196F3" dark class="mb-3" @click="goContact()">
        문의하기
      </v-btn>
      <v-btn fab x-small color="#2196F3" dark outlined style="background-color: white" @click="goBottom()">
        <v-icon>mdi-chevron-down</v-icon>
      </v-btn>
    </div>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      navDrawer: false,
    }
  },
  methods: {
    goHome() {
      this.$router.push('/').catch(() => {})
      scrollTo(0, 0)
      this.navDrawer = false
    },
    goSolution() {
      this.$router.push('/solution').catch(() => {})
      scrollTo(0, 0)
      this.navDrawer = false
    },
    goRecolumn() {
      window.open('https://www.recolumn.com/')
      this.navDrawer = false
    },
    goInventier() {
      window.open('https://inventier.cogcom.kr/')
      this.navDrawer = false
    },
    goAiVoucher() {
      this.$router.push('/ai-voucher').catch(() => {})
      scrollTo(0, 0)
      this.navDrawer = false
    },
    goContact() {
      this.$router.push('/contact').catch(() => {})
      scrollTo(0, 0)
      this.navDrawer = false
    },
    goTechnology() {
      this.$router.push('/technology').catch(() => {})
      scrollTo(0, 0)
      this.navDrawer = false
    },
    goService() {
      this.$router.push('/service').catch(() => {})
      scrollTo(0, 0)
      this.navDrawer = false
    },
    goCareer() {
      window.open('https://www.rocketpunch.com/companies/cogcom')
      this.navDrawer = false
    },
    closeNavDrawer() {
      this.navDrawer = false
    },
    goTop() {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    },
    goBottom() {
      window.scroll({
        top: document.body.scrollHeight,
        left: 0,
        behavior: 'smooth'
      })
    },
  }
}
</script>

<style>
div {
  word-break: keep-all;
}

@media (min-width: 601px) {
  .navbar-logo {
    padding-left: 10vh;
  }
  .fab-area {
    position: fixed;
    bottom: 24px;
    right: 24px;
  }
}
@media (max-width: 600px) {
  .navbar-logo {
    padding-left: 10px
  }
  .fab-area {
    position: fixed;
    bottom: 12px;
    right: 12px;
  }
}
</style>